import React from "react"
import { Row, Col } from "react-bootstrap"
import classnames from "classnames"
import Button from "../Layout/Button"
import styles from "./BannerAr.module.scss"
import { useDownloadApp } from "../../actions/useDownloadApp"
import { useGetItOn } from "../../actions/useDownloadApp"
import { useDeviceDetection } from "../../actions/useDeviceDetection"

const BannerAr = ({ cta, isMobileBanner }) => {
  const { isMobile, isIOS, isAndroid } = useDeviceDetection()

  return (
    <div
      className={classnames(styles.imgFooter, "d-flex", "align-items-center")}
      style={{
        backgroundColor: "#EFEBE8",
      }}>
      <div className={`container-fluid z-1 h-100`}>
        <Row className="h-100">
          <Col
            className="p-3 py-md-5 pb-md-0 pl-md-6 pr-md-0 h-100 d-flex align-items-center justify-content-center"
            xs={12}
            sm={6}>
            <Row className="text-center text-sm-left">
              <Col xs={12} className="m0a">
                <p className="m-0 p-0 text-black fs-24px fs-mobile-20px">
                  View these beautiful pieces in your home using
                </p>
                <p className="m-0 p-0 text-black fs-24px fs-mobile-20px">
                  Augmented Reality technology.
                </p>
              </Col>
              <Col xs={12} className="m0a mt-4">
                {(!isMobile || isIOS) && (
                  <Button
                    isAppleButton={isMobileBanner}
                    onClick={useDownloadApp}
                    sx="ml-0 mr-2 bg-transparent"
                    variant="rounded">
                    {cta}
                  </Button>
                )}
                {(!isMobile || isAndroid) && (
                  <Button
                    isAndroidButton={isMobileBanner}
                    onClick={useGetItOn}
                    sx="mx-0 bg-transparent"
                    variant="rounded">
                    {cta}
                  </Button>
                )}
              </Col>
            </Row>
          </Col>
          <Col
            className="pt-3 pt-md-5 pr-md-6 h-100 d-none d-sm-flex align-items-end justify-content-center"
            xs={6}>
            <img
              src="images/banner_ar_app.svg"
              alt="ar image"
              style={{ maxHeight: "100%" }}
            />
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default BannerAr
