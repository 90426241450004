import Head from "next/head"
import React from "react"

export const SeoTags = ({
  description,
  title,
  image = "https://artplacer-nextjs.herokuapp.com/artplacer-logo.png",
  url,
  canonical,
}) => {
  return (
    <Head>
      <meta name="description" content={description} />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="ArtPlacer" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      <meta property="og:site_name" content="ArtPlacer" />
      <meta property="og:title" content={title} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="og:image" content={image} />
      <meta property="og:description" content={description} />
      {canonical && <link rel="canonical" href={canonical}></link>}
    </Head>
  )
}
