import { useRouter } from "next/router"
import React from "react"
import { images } from "../../../services/utils/images"
import { useNavigateTo } from "../../../services/utils/useNavigateTo"
import Banner from "../../components/Banner"
import BannerFooter from "../../components/BannerFooter"
import BannerAr from "../../components/BannerAr"
import Container from "../../components/Layout/Container"
import { HelmetTitle } from "../../components/Layout/HelmetTitle"
import { SeoTags } from "../../components/Layout/SeoTags"
import { FeaturedArtworks } from "./FeaturedArtworks"
import { HomeCarousel } from "./HomeCarousel"
import { MonthlyPicks } from "./MonthlyPicks"
import { FeaturedRooms } from "./FeaturedRooms"
import { FeaturedExhibitions } from "./FeaturedExhibitions"

export const HomePage = ({ homeInfo }) => {
  const navigateTo = useNavigateTo()

  const onClickBanner = () => {
    navigateTo("/artworks")
  }

  return (
    <>
      <SeoTags
        title="Discover ArtPlacer | Explore art and galleries, visualize art in space"
        description="Discover ArtPlacer is where the ArtPlacer community gathers together. Explore and find the most exquisite artworks and the best galleries in the world."
        image="https://artplacer-nextjs.herokuapp.com/artplacer-logo.png"
        url="https://discover.artplacer.com/"
      />
      <HelmetTitle
        hide={true}
        title="Discover ArtPlacer | Explore art and galleries, visualize art in space"
      />
      <Banner
        height={450}
        image={images.home.header}
        title="Explore the world of art"
        description="Dive into ArtPlacer’s community of artists and galleries. Discover portfolios from around the world."
        cta="START EXPLORING"
        onClick={onClickBanner}
      />
      <Container>
        <div className="mb-5 pt-5">
          <FeaturedExhibitions
            featuredExhibitions={homeInfo.featured_exhibitions}
          />
        </div>
        <div className="mb-5">
          <HomeCarousel featuredGalleries={homeInfo.featured_galleries} />
        </div>
        <div className="mb-5">
          <FeaturedArtworks featuredArtworks={homeInfo.featured_artworks} />
        </div>
        <div className="mb-5">
          <MonthlyPicks artworks={homeInfo.monthly_picks} />
        </div>
        {/* <div className="mb-5">
          <FeaturedRooms featuredSpaces={homeInfo.featured_spaces} />
        </div> */}
      </Container>
      <div className="container mb-4">
        <BannerAr cta="DOWNLOAD THE APP" isMobileBanner />
      </div>
      <div className="container mb-4">
        <BannerFooter
          image={images.home.footer}
          cta="Free 7-day Trial"
          showEmail={true}
          textAlign="center"
          titleClasses="fs-24px fs-mobile-20px font-secondary fw-400">
          Showcase your work. <br />
          Create your own Gallery Profile.
        </BannerFooter>
      </div>
    </>
  )
}
export default HomePage
// cache 115617082024
