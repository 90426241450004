import React from "react"
import { Row, Col } from "react-bootstrap"
import classnames from "classnames"
import { useGetStartedAction } from "../../actions/useGetStartedAction"
import Button from "../Layout/Button"
import { ButtonCta } from "../Layout/ButtonCta"
import styles from "./BannerFooter.module.scss"
import { useDownloadApp } from "../../actions/useDownloadApp"
import { useGetItOn } from "../../actions/useDownloadApp"
import { useDeviceDetection } from "../../actions/useDeviceDetection"

const BannerFooter = ({
  children,
  cta,
  isMobileBanner,
  showEmail,
  textAlign = "left",
  titleClasses,
  image,
}) => {
  const { email, setEmail, handleClick } = useGetStartedAction()
  const { isMobile, isIOS, isAndroid } = useDeviceDetection()

  return (
    <div
      className={classnames(styles.imgFooter, "d-flex", "align-items-center")}
      style={{
        backgroundImage: `url(${image})`,
      }}>
      <div className={`container-fluid z-1 text-${textAlign}`}>
        <Row>
          <Col
            className="px-md-0"
            xs={12}
            sm={12}
            md={textAlign === "center" ? 12 : 8}
            lg={textAlign === "center" ? 12 : 8}>
            <h2 className={classnames(styles.white, titleClasses)}>
              <div className="ml-0 ml-md-5">{children}</div>
            </h2>
          </Col>
        </Row>
        {showEmail && (
          <Row
            className={textAlign === "center" ? "justify-content-center" : ""}>
            <Col
              xs={12}
              md={3}
              className={classnames(
                "px-0 px-md-0 ml-0 ml-md-5",
                styles.mobilecta,
              )}>
              <ButtonCta
                className={classnames(styles["custom-cta"])}
                onClick={handleClick}
                title={cta}
              />
            </Col>
          </Row>
        )}
        {!showEmail && (
          <Row className={`text-${textAlign}`}>
            <Col className="m0a">
              {(!isMobile || isIOS) && (
                <Button isAppleButton={isMobileBanner} onClick={useDownloadApp}>
                  {cta}
                </Button>
              )}
              {(!isMobile || isAndroid) && (
                <Button isAndroidButton={isMobileBanner} onClick={useGetItOn}>
                  {cta}
                </Button>
              )}
            </Col>
          </Row>
        )}
      </div>
    </div>
  )
}

export default BannerFooter
